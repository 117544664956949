
import Vue, { PropType } from "vue";

import { Building } from "@/interfaces";

export default Vue.extend({
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  computed: {
    address(): string {
      return `${this.building.city?.name ?? ""}${this.building.town?.name ??
        ""}${this.building.chome}${this.building.block}`;
    },
  },
});
