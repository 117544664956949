
import Vue, { PropType } from "vue";

import store from "@/store";

import { Building, Company, Notice } from "@/interfaces";
import http from "@/http";

import MyOverview from "@/components/building/MyOverview.vue";
import MyTable from "@/components/notice/MyTable.vue";

import { mdiAccount, mdiPlus } from "@mdi/js";

export interface DataType {
  company_id: number;
  notices: Notice[];
}

export default Vue.extend({
  components: {
    MyOverview,
    MyTable,
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  data(): DataType {
    return {
      company_id: 0,
      notices: [],
    };
  },
  computed: {
    icon() {
      return {
        mdiAccount,
        mdiPlus,
      };
    },
    companies(): Company[] {
      return store.state.companies;
    },
  },
  async mounted() {
    if (this.building.subcontractor) {
      this.company_id = this.building.subcontractor.company_id;
    }

    const url = `/buildings/${this.building.id}/notices`;

    const { data } = await http.get<Notice[]>(url);

    this.notices = data;
  },
  methods: {
    async change() {
      const url = `buildings/${this.building.id}`;

      const { data } = await http.put(url, {
        company_id: this.company_id,
      });

      this.building.subcontractor = data.subcontractor;
    },
  },
});
