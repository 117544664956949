
import Vue, { PropType } from "vue";

import MyStreetAddress from "@/components/building/MyStreetAddress.vue";
import MyLifeline from "@/components/building/MyLifeline.vue";
import MyFeature from "@/components/building/MyFeature.vue";

import { Building } from "@/interfaces";

export default Vue.extend({
  components: {
    MyStreetAddress,
    MyLifeline,
    MyFeature,
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
});
