
import Vue, { PropType } from "vue";

import { Building } from "@/interfaces";

export default Vue.extend({
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
});
