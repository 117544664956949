
import Vue, { PropType } from "vue";

import { Attachment, Notice } from "@/interfaces";

import { date, excerpt } from "@/filters";

import http from "@/http";

import FileDownload from "js-file-download";

export default Vue.extend({
  filters: {
    date,
    excerpt
  },
  props: {
    notices: {
      type: Array as PropType<Notice[]>,
      required: true
    }
  },
  methods: {
    async download(attachment: Attachment) {
      const { data } = await http.get(`attachments/${attachment.id}`, {
        responseType: "blob"
      });

      FileDownload(data, `${attachment.id}.pdf`);
    }
  }
});
